import {
  Card,
  CardActionArea,
  CardContent,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import {
  CategoryEntity,
  BaseEventEntity,
  ExtraCategoryEntity,
} from "../entities";
import { MyErrorBoundary } from "../MyErrorBoundary";
import { EventFilter, filterEvents } from "./EventFilter";
import { Sidebar } from "./Sidebar";
import { Event } from "./Event";
import { ExtraCategories } from "./ExtraCategories";
import useSWR from "swr";
import { useUser } from "../user";
import { makeStyles } from "@material-ui/core/styles";
import { SPACING } from "../util";
import { useRouter } from "next/router";
import { ELTERNSPRECHTAG_CATEGORY_ID } from "../shared/constants";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(SPACING),
  },
}));

function CategoryCallout({
  categoryId,
  title,
  description,
}: {
  categoryId: number;
  title: string;
  description: string;
}) {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Card color="primary" className={classes.root}>
      <CardActionArea
        onClick={() =>
          router
            .push(`/category/${encodeURIComponent(categoryId)}`)
            .then(() => window.scrollTo(0, 0))
        }
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function Startpage({
  events,
  extraCategories,
  category,
}: {
  events: BaseEventEntity[];
  extraCategories: ExtraCategoryEntity[];
  category?: CategoryEntity | null;
}) {
  const isStartpage = !category;
  const isMobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const [filter, setFilter] = useState("");
  const [filterWithRegistration, setFilterWithRegistration] = useState(false);
  const filteredEvents = filterEvents(events, {
    filter,
    filterWithRegistration,
  });

  const user = useUser();
  const isNormalUser =
    !user.permissions.TEACHER &&
    !user.permissions.SUPERTEACHER &&
    !user.permissions.SUPERADMIN;
  const isTeacher =
    !user.permissions.SUPERADMIN &&
    (user.permissions.TEACHER || user.permissions.SUPERTEACHER);

  const body = useMemo(() => {
    return {
      method: "POST",
      body: JSON.stringify({ eids: events.map((event) => event.eid) }),
    };
  }, [events]);
  const { data: permissions, isValidating } = useSWR(
    isTeacher ? ["/api/permissions", body] : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return (
    <>
      <MyErrorBoundary>
        <Sidebar extraCategories={extraCategories} category={category} />
      </MyErrorBoundary>
      {/*
        !category && (
          <CategoryCallout
            categoryId={ELTERNSPRECHTAG_CATEGORY_ID}
            title="Elternsprechtag"
            description="Hier tippen zur Anmeldung für den Elternsprechtag (Anmeldung möglich ab 11.02. um 18:00)."
          />
        )
      */}
      {events.length === 0 ? (
        <Alert severity="info">
          {isStartpage
            ? "Es gibt aktuell keine Veranstaltungen und Angebote."
            : "Es gibt in dieser Kategorie aktuell keine Veranstaltungen und Angebote."}
        </Alert>
      ) : (
        <EventFilter
          setFilter={setFilter}
          setFilterWithRegistration={setFilterWithRegistration}
        />
      )}
      {filteredEvents.map((event) => (
        <Event
          key={event.eid}
          event={event}
          initiallyCollapsed={category?.eventListLayout === "dense"}
          canEdit={
            user.permissions.SUPERADMIN
              ? "yes"
              : isNormalUser
              ? "no"
              : isValidating || !permissions
              ? "loading"
              : permissions[event.eid] === true
              ? "yes"
              : "no"
          }
        />
      ))}
      {isStartpage && isMobile && (
        <>
          <ExtraCategories extraCategories={extraCategories} isMobile={false} />
          <br />
        </>
      )}
      {(filter || filterWithRegistration || isStartpage) && (
        <Alert severity="warning">
          Keine weiteren Angebote gefunden.
          {isStartpage && !isMobile && (
            <>
              {" "}
              <strong>
                Bitte beachten: Einige Angebote werden auf der Startseite nicht
                angezeigt. Diese Angebote sind in der{" "}
                <Link href="#extra-categories">
                  Seitenleiste unter "Weitere Angebote" aufgeführt
                </Link>
                .
              </strong>
            </>
          )}
        </Alert>
      )}
      <div style={{ clear: "both" }} />
    </>
  );
}
