import { BaseEventEntity } from "../entities";

export function filterEvent(filter: string, requireRegistration = false) {
  const filterLower = filter.toLowerCase();
  return (event: BaseEventEntity) =>
    (filterLower === "" ||
      event.title.toLowerCase().includes(filterLower) ||
      event.teaser.toLowerCase().includes(filterLower) ||
      event.addresses.some((address) =>
        (address.description ?? address.address)
          .toLowerCase()
          .includes(filterLower)
      )) &&
    (requireRegistration === false ||
      event.isRegistrationAllowed ||
      event.externalRegistrationLink);
}
