import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  makeStyles,
  ListItemIcon,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import React from "react";
import useSWR from "swr";
import { emUrl } from "../util";
import { Feedback } from "../ui/Feedback";
import NextLink from "next/link";
import { FeedbackEntity } from "../entities";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import ForumIcon from "@material-ui/icons/Forum";

const useStyles = makeStyles(() => ({
  giveFeedbackButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    fontWeight: "bold",
    paddingBlock: 12,
  },
  buttonIcon: {
    paddingRight: 0,
    marginRight: 12,
    minWidth: "unset",
  },
}));

function ActionButtons({
  isMobile,
  isFeedbackAllowedNow,
}: {
  isMobile: boolean;
  isFeedbackAllowedNow: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      <NextLink href="/feedbacks" passHref>
        <ListItem button component="a">
          <ListItemIcon className={classes.buttonIcon}>
            <ForumIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              isMobile ? "Feedbacks anzeigen" : "Weitere Feedbacks anzeigen"
            }
          />
        </ListItem>
      </NextLink>
      {isFeedbackAllowedNow && (
        <NextLink href="/feedback" passHref>
          <ListItem
            component={Button}
            color="secondary"
            variant="contained"
            size="large"
            className={classes.giveFeedbackButton}
          >
            <ListItemIcon className={classes.buttonIcon}>
              <StarHalfIcon />
            </ListItemIcon>
            Feedback geben
          </ListItem>
        </NextLink>
      )}
    </>
  );
}

export function Feedbacks({ isMobile }: { isMobile: boolean }) {
  const { data, error, revalidate, isValidating } = useSWR<{
    feedbacks: FeedbackEntity[];
    isFeedbackAllowedNow: boolean;
  }>(emUrl("rest", "startPageFeedbacks"), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          Veranstaltungsfeedbacks
        </Typography>
      </CardContent>
      {!data || error ? (
        error && !isValidating ? (
          <Alert
            severity="error"
            action={
              <Button onClick={() => revalidate()}>nochmal versuchen</Button>
            }
          >
            Feedbacks konnten nicht geladen werden.
          </Alert>
        ) : (
          <>
            {!isMobile ? (
              <>
                <Box pb={2} px={2}>
                  <Skeleton variant="rect" width={"100%"} height={60} />
                </Box>
                <Box pb={1} px={2}>
                  <Skeleton variant="rect" width={"100%"} height={60} />
                </Box>
                <Box pb={1} px={2}>
                  <Skeleton variant="rect" width={"100%"} height={60} />
                </Box>
              </>
            ) : (
              <>
                <Box pt={2} pb={0.5} px={2}>
                  <Skeleton variant="rect" width={"100%"} height={30} />
                </Box>
                <Box pt={2} pb={0.5} px={2}>
                  <Skeleton variant="rect" width={"100%"} height={30} />
                </Box>
              </>
            )}
          </>
        )
      ) : (
        <>
          {!isMobile && data.feedbacks.length === 0 && (
            <Alert severity="info">Es gibt keine aktuellen Feedbacks.</Alert>
          )}
          <List disablePadding>
            {!isMobile &&
              data.feedbacks.map((feedback) => (
                <Feedback
                  key={feedback.fbid}
                  feedback={feedback}
                  variant="short"
                  showEventName={true}
                />
              ))}
            <ActionButtons
              isFeedbackAllowedNow={data.isFeedbackAllowedNow}
              isMobile={isMobile}
            />
          </List>
        </>
      )}
    </Card>
  );
}
