import {
  makeStyles,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Link from "next/link";
import React from "react";
import { ExtraCategoryEntity } from "../entities";
import { colorToHex, colorToRgba } from "../util";

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRightStyle: "solid",
    borderRightColor: theme.palette.secondary.main,
    borderRightWidth: theme.spacing(0.5),
  },
  listBtn: {
    textAlign: "center",
  },
}));

export function ExtraCategories({
  extraCategories,
  isMobile,
}: {
  extraCategories: ExtraCategoryEntity[];
  isMobile: boolean;
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card id="extra-categories" variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          Weitere Angebote
        </Typography>
      </CardContent>
      {/*<Alert severity="info">
        Angebote der folgenden Kategorien werden auf der Startseite nicht
        angezeigt.
      </Alert>*/}
      <List disablePadding dense>
        {extraCategories
          .filter((extraCategory) => extraCategory.count > 0)
          .sort((a, b) => {
            const sort = b.category.sidebarSort - a.category.sidebarSort;
            if (sort !== 0) {
              return sort;
            }
            return a.category.title.localeCompare(b.category.title);
          })
          .map((extraCategory, i) => {
            if (isMobile && !expanded && i >= 3) {
              return null;
            }

            const color = colorToHex(extraCategory.category.color);
            const mutedColor = colorToRgba(extraCategory.category.color, 0.15);

            const furtherEventCount = extraCategory.count - 1;

            return (
              <Link
                key={extraCategory.category.cid}
                href={`/category/${encodeURIComponent(
                  extraCategory.category.cid
                )}`}
                passHref
                prefetch={false} // Do not prefetch expensive-to-calculate start pages
              >
                <ListItem
                  button
                  component="a"
                  className={classes.listItem}
                  style={
                    color && mutedColor
                      ? {
                          borderRightColor: color,
                          background: mutedColor,
                        }
                      : undefined
                  }
                >
                  <ListItemText
                    primary={extraCategory.category.title}
                    secondary={
                      <>
                        <em>{extraCategory.lastUpdatedEvent?.title}</em>
                        {furtherEventCount > 0 && (
                          <>
                            {" "}
                            und{" "}
                            {furtherEventCount === 1
                              ? "1 weiteres Angebot"
                              : `${furtherEventCount} weitere Angebote`}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </Link>
            );
          })}
        {isMobile && !expanded && (
          <>
            <Divider />
            <ListItem button onClick={() => setExpanded(true)}>
              <ListItemText
                primary="Mehr ausgeblendete Angebote anzeigen"
                className={classes.listBtn}
              />
            </ListItem>
          </>
        )}
      </List>
    </Card>
  );
}
