import {
  Button,
  Card,
  Checkbox,
  Fab,
  FormControlLabel,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom/Zoom";
import React, { useState, useRef, useEffect } from "react";
import { BOTTOM_NAV_HEIGHT, useBottomNavigation } from "../MyBottomNavigation";
import SearchIcon from "@material-ui/icons/Search";
import { BaseEventEntity } from "../entities";
import { filterEvent } from "../shared/event-filter";

export function filterEvents(
  events: BaseEventEntity[],
  {
    filter,
    filterWithRegistration,
  }: { filter: string; filterWithRegistration: boolean }
) {
  return events.filter(filterEvent(filter, filterWithRegistration));
}

const useFilterStyles = makeStyles<Theme, { bottomNavigationVisible: boolean }>(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(2),
    },
    wrapper: {
      padding: theme.spacing(2),
    },
    fab: {
      position: "fixed",
      zIndex: theme.zIndex.appBar,
      bottom: ({ bottomNavigationVisible }) =>
        theme.spacing(2) + (bottomNavigationVisible ? BOTTOM_NAV_HEIGHT : 0),
      right: theme.spacing(2),
    },
  })
);

export function EventFilter({
  setFilter,
  setFilterWithRegistration,
}: {
  setFilter: (f: string) => void;
  setFilterWithRegistration: (b: boolean) => void;
}) {
  const bottomNavigationVisible = useBottomNavigation();
  const classes = useFilterStyles({ bottomNavigationVisible });
  const [value, setValue] = useState("");
  const [isDebouncing, setIsDebouncing] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setIsDebouncing(true);
    const timerId = setTimeout(() => {
      setFilter(value);
      setIsDebouncing(false);
    }, 500);

    return () => clearTimeout(timerId);
  }, [value, setFilter]);

  const searchField = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [focused, setFocused] = useState(false);

  return (
    <Card variant="outlined" className={classes.root} id="filter">
      <div className={classes.wrapper}>
        <TextField
          inputRef={searchField}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.key === "Enter") {
              searchField.current?.blur();
            }
          }}
          label="Angebot finden"
          placeholder="Stichwort/Titel eingeben"
          fullWidth
          variant="filled"
          margin="none"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            endAdornment: value !== "" && (
              <Button onClick={() => setValue("")}>Löschen</Button>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={false}
              onChange={(e) => setFilterWithRegistration(e.target.checked)}
            />
          }
          label="Nur Angebote mit Anmeldemöglichkeit anzeigen"
        />
      </div>
      {isDebouncing && <LinearProgress />}
      <Zoom in={!focused && isMobile} unmountOnExit>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="Angebote durchsuchen"
          onClick={() => searchField.current?.focus()}
        >
          <SearchIcon />
        </Fab>
      </Zoom>
    </Card>
  );
}
