import {
  makeStyles,
  useMediaQuery,
  Theme,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import Link from "next/link";
import React, { useState } from "react";
import { CategoryEntity, ExtraCategoryEntity } from "../entities";
import { useUser } from "../user";
import { SPACING } from "../util";
import { ExtraCategories } from "./ExtraCategories";
import { Feedbacks } from "./Feedbacks";
import { MyRegistrations } from "./MyRegistrations";
import dynamic from "next/dynamic";
import { AdminButton } from "../ui/AdminButton";
import { generateBackTo } from "../backTo";

const LazyStatistics = dynamic<{}>(
  import("../Statistics").then((mod) => mod.Statistics)
);

const useSidbarStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      float: "right",
      width: "100%", // IE compat
      maxWidth: 400,
      marginLeft: theme.spacing(SPACING),
    },
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const Sidebar = React.memo(function Sidebar({
  extraCategories,
  category,
}: {
  category?: CategoryEntity | null;
  extraCategories: ExtraCategoryEntity[];
}) {
  const classes = useSidbarStyles();
  const isMobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const user = useUser();
  const [admin, setAdmin] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container spacing={SPACING}>
        {category && (
          <>
            <Grid item xs={12}>
              <CategoryBanner category={category} setAdmin={setAdmin} />
            </Grid>
            {admin && (
              <Grid item xs={12}>
                <CategoryEdit category={category} />
              </Grid>
            )}
          </>
        )}
        {!isMobile && (
          <Grid item xs={12} sm={6} md={12}>
            <MyRegistrations
              isMobile={isMobile}
              backTo={generateBackTo(
                "s",
                category ? { cid: category.cid } : {}
              )}
            />
          </Grid>
        )}
        {extraCategories.length > 0 && (
          <Grid item xs={12} sm={6} md={12}>
            <ExtraCategories
              extraCategories={extraCategories}
              isMobile={isMobile}
            />
          </Grid>
        )}
        {!category && (
          <Grid item xs={12} sm={6} md={12}>
            <Feedbacks isMobile={isMobile} />
          </Grid>
        )}
        {!isMobile && user.permissions.TEACHER && (
          <Grid item xs={12}>
            <LazyStatistics />
          </Grid>
        )}
      </Grid>
    </div>
  );
});

const CategoryEdit = dynamic<{ category: CategoryEntity }>(() =>
  import("../../src/Startpage/CategoryEdit").then((mod) => mod.CategoryEdit)
);

const useStyles = makeStyles((theme) => ({
  category: {},
}));

function CategoryBanner({
  category,
  setAdmin,
}: {
  category: CategoryEntity;
  setAdmin: (b: boolean) => void;
}) {
  const classes = useStyles();
  const user = useUser();

  return (
    <>
      <Card className={classes.category}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {category.title}
          </Typography>
          <Typography>
            Auf dieser Seite werden nur Angebote der obigen Kategorie angezeigt.
          </Typography>
        </CardContent>
        <CardActions>
          <Link href="/" passHref>
            <Button component="a" color="primary" variant="contained">
              Zurück zur Startseite
            </Button>
          </Link>
          {user.permissions.TEACHER && (
            <AdminButton onClick={() => setAdmin(true)} variant="text">
              Admin
            </AdminButton>
          )}
        </CardActions>
      </Card>
    </>
  );
}
