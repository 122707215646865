import { ListItem, ListItemText, NoSsr, Typography } from "@material-ui/core";
import React from "react";
import { FeedbackEntity } from "../entities";
import { times } from "../util";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import nl2br from "react-nl2br";

export function Feedback({
  feedback,
  variant,
  showEventName,
}: {
  feedback: FeedbackEntity;
  variant: "detailed" | "short";
  showEventName: boolean;
}) {
  const stars = feedback.stars;
  return (
    <ListItem divider>
      <ListItemText
        primary={showEventName && feedback.event.title}
        secondary={
          <>
            {stars !== null && (
              <>
                {times(5, (i) =>
                  stars >= i + 1 ? (
                    <StarIcon key={i} fontSize="small" color="secondary" />
                  ) : (
                    <StarBorderIcon
                      key={i}
                      fontSize="small"
                      color="secondary"
                    />
                  )
                )}
                <br />
              </>
            )}
            {variant === "detailed"
              ? nl2br(feedback.comment.trim())
              : feedback.comment}
            {variant === "detailed" && (
              <Typography
                variant="body2"
                color="textSecondary"
                component="span"
              >
                <br />
                geschrieben am{" "}
                <NoSsr>
                  {new Date(feedback.createdAt * 1000).toLocaleDateString()}
                </NoSsr>
              </Typography>
            )}
          </>
        }
        secondaryTypographyProps={
          variant === "detailed" ? { color: "textPrimary" } : {}
        }
      />
    </ListItem>
  );
}
